import React from "react"
import { Layout } from "../components/Layout"

const Main = () => (
  <Layout currentNav={{ menu: true }} pageTitle="メニュー" fileName="menu.js">
    <h1>メニュー</h1>
    <div className="menu">
      <h2>カット</h2>
      <dl>
        <dt>カット＆ブロー</dt>
        <dd>￥４，４００　（税込）</dd>
        <dt>中学生</dt>
        <dd>￥３，８５０　（税込）</dd>
        <dt>小学生</dt>
        <dd>￥２，７５０　（税込）</dd>
        <dt>小学生未満</dt>
        <dd>￥１，６５０　（税込）</dd>
        <dt>前髪・眉カット</dt>
        <dd>各 ￥５５０　（税込）</dd>
      </dl>
      <h2>パーマ (カット別）</h2>
      <dl>
        <dt>パーマ</dt>
        <dd>￥５，１７０（税込）より</dd>
        <dt>ホット系パーマ</dt>
        <dd>￥９，０２０（税込）より</dd>
        <dt>ストレートパーマ</dt>
        <dd>￥９，５７０　（税込）より</dd>
        <dt>ノーアイロン　ストレート</dt>
        <dd>￥５，７２０　（税込）より</dd>
        <dt>ポイントパーマ</dt>
        <dd>￥４，０７０（税込）より</dd>
      </dl>
      <h2>カラー　(カット別）</h2>
      <dl>
        <dt>リタッチカラー</dt>
        <dd>￥４，８４０（税込）より</dd>
        <dt>バージンカラー</dt>
        <dd>￥５，１７０　（税込）より</dd>
        <dt>ポイントカラー</dt>
        <dd>￥３，０００　（税込）より</dd>
      </dl>
      <h2>マニキュア　(カット別）</h2>
      <dl>
        <dt>リタッチ</dt>
        <dd>￥４，６２０（税込）より</dd>
        <dt>バージン</dt>
        <dd>￥６，２７０（税込）より</dd>
      </dl>
      <h2>トリートメント</h2>
      <dl>
        <dt>トリートメント</dt>
        <dd>￥２，０９０（税込）より</dd>
        <dt>水トリートメント　（スチーム）</dt>
        <dd>￥５５０　（税込）より</dd>
      </dl>
      <h2>シャンプー・セット</h2>
      <dl>
        <dt>シャンプー</dt>
        <dd>￥１，１００　（税込）より 　※髪の長さにより異なります</dd>
        <dt>ワンシャンプー</dt>
        <dd>￥５５０　（税込）より</dd>
        <dt>セット</dt>
        <dd>￥１，８７０　（税込）より　※髪の長さにより異なります</dd>
        <dt>アップ</dt>
        <dd>￥３，３００　（税込）より ※ スタイルによって異なります</dd>
      </dl>
      <h2>エステ(フェイシャル）・メイク</h2>
      <dl>
        <dt>エステ</dt>
        <dd>￥３，６３０（税込）</dd>
        <dt>エステ（フル）</dt>
        <dd>￥５，７２０　（税込）</dd>
        <dt>炭酸パック</dt>
        <dd>￥５５０　（税込）</dd>
        <dt>エステプラセンタ</dt>
        <dd>￥２，９７０　（税込）</dd>
        <dt>水エステ</dt>
        <dd>￥３，８５０　（税込）</dd>
        <dt>ハンドマッサージ</dt>
        <dd>￥５５０　（税込）</dd>
        <dt>メイク</dt>
        <dd>￥３，３００　（税込） より</dd>
      </dl>
      <h2>着付け</h2>
      <dl>
        <dt>留袖・訪問着</dt>
        <dd>￥１０，０００（税込）</dd>
        <dt>振袖</dt>
        <dd>￥１５，０００（税込）</dd>
        <dt>袴</dt>
        <dd>￥９，０００　（税込）</dd>
        <dt>浴衣</dt>
        <dd>￥７，０００　（税込）</dd>
      </dl>
      <p>
        ※成人式、着付け、ヘアメイクのご予約を承ります。
        ※七五三、卒業式等の着付けやヘアーセットもお気軽にお問い合わせください。
      </p>
    </div>
  </Layout>
)

export default Main
